import type { AxiosInstance } from "axios"

import axios from "@/axios/api"
import type {
  WeveActivity,
  ActivityId,
  WeveGame,
  RoomId
} from "./export.service.types"

export default class ExportService {
  constructor(private readonly client: AxiosInstance = axios) {}

  async exportActivity(activity: WeveActivity): Promise<ActivityId> {
    const { data } = await this.client.post<{ id: ActivityId }>(
      "/import/activity",
      { activity }
    )
    return data.id
  }

  async exportGame(game: WeveGame): Promise<RoomId> {
    const { data } = await this.client.post<{ id: RoomId }>("/import/game", {
      game
    })
    return data.id
  }
}
